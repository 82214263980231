import React, { useState, useRef } from "react"
import { useEventListener } from "./Utilities/useEventListener";
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Nav() {
    const [open, setOpen] = useState(false);
    const navRef = useRef(null);
    const handleClickOutside = (e) => {
        if (!open) return;
        if (!navRef.current.contains(e.target)) setOpen(false);
    }
    useEventListener("touchstart", handleClickOutside);
    return(
        <nav ref={navRef} className={`siteNav ${open ? "open" : "closed"}`}>
            <div className="topBanner">
                <div className="siteLogo">
                    <Link to="/">
                        <picture>
                            <source srcSet="https://i.imgur.com/8Q2JrUG.png" media="(min-width: 770px)" />
                            <img src="https://i.imgur.com/lSgwByg.png" alt="Funovus Logo" width="56" height="56" />
                        </picture>
                    </Link>
                    <div id="sidebarBtn" onClick={() => setOpen(!open)}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                </div>
                <div className="navLinks">
                    <Link activeClassName="current" to="/">Home</Link>
                    <Link activeClassName="current" to="/games">Games</Link>
                    <Link activeClassName="current" to="/careers">Careers</Link>
                    <Link activeClassName="current" to="/blogs">Blogs</Link>
                </div>
            </div>
        </nav>
    )
}

export default Nav